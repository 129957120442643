import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.css';
import AFTableColumn from 'af-table-column';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from './utils/axios.js'
import VueBus from 'vue-bus'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import "dayjs/locale/zh-cn";
dayjs.extend(weekday)
dayjs.locale('zh-cn')

//解决el-radio隐藏切换报错问题
Vue.directive('removeAriaHidden', {
  bind (el, binding) {
    const ariaEls = el.querySelectorAll('.el-radio__original')
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden')
    })
  }
})

Vue.prototype.$bus = new Vue();
Vue.prototype.$axios = axios
// 时间选择器
Vue.prototype.$day = dayjs

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueBus);
Vue.use(AFTableColumn)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
